import React from 'react'
import Router from 'next/router'
import { motion } from 'framer-motion'
import cx from 'classnames'
import css from 'styled-jsx/css'
import Image from 'next/image'

import useOnClickOutside from '../hooks/useOnClickOutside'
import { useUser, useAuthContext } from '../context/Auth'
import Button from './Button'

import UserDefaultImg from '../../public/user-default.svg'
import LoginIcon from '../../public/login-icon.svg'
import SearchIcon from '../../public/search-icon.svg'
import Link from 'next/link'

const bodyAnimation = {
  close: {
    scale: 0.7,
    opacity: 0,
  },
  open: {
    scale: 1,
    opacity: 1,
  },
}

function UserDropdown() {
  const user = useUser()
  const authContext = useAuthContext()

  const [isOpen, setOpen] = React.useState<boolean>(false)
  const ref = React.useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, () => setOpen(false))

  async function handleLogout() {
    await fetch('/api/logout')
    authContext.changeData({ user: null })
  }

  return (
    <div ref={ref}>
      {user ? (
        <div className="relative">
          <button
            className="user-button"
            onClick={() => setOpen((prevOpen) => !prevOpen)}
          >
            <Image src={UserDefaultImg} alt="Richard B. Handler photo" />
            <span className="user-name">
              {user.firstName || user.lastName ? (
                <>
                  {user.firstName}
                  {` `}
                  {user.lastName}
                </>
              ) : (
                user.displayName
              )}
            </span>
            <span className="caret-down" />
          </button>
          <motion.div
            className={cx('dropdown-body', components.className)}
            variants={bodyAnimation}
            transition={{ duration: 0.3 }}
            initial="close"
            animate={isOpen ? 'open' : 'close'}
          >
            <Link href="/profile">
              <a className="dropdown-item">Profile</a>
            </Link>
            <button className="dropdown-item" onClick={() => handleLogout()}>
              Logout
            </button>
          </motion.div>
        </div>
      ) : (
        <>
          <Button
            className={cx('btn-access', components.className)}
            outline
            onClick={() => Router.push('/signup')}
          >
            Get Access
          </Button>
          <Button
            className={cx('btn-transparent', components.className)}
            outline
            onClick={() => Router.push('/login')}
          >
            <Image src={LoginIcon} alt="Login icon" />
            <span className="ml-2">Login</span>
          </Button>
          {/* <Button
            className={cx(
              'btn-transparent',
              'btn-search',
              components.className
            )}
            outline
          >
            <Image src={SearchIcon} alt="Search icon" />
          </Button> */}
        </>
      )}
      <style jsx>{`
        .user-button {
          @apply flex items-center;
        }
        .user-name {
          @apply ml-2 hidden font-inter text-[16px] font-semibold leading-[19.36px] tracking-[0.3px] text-black lg:block;
        }
        .caret-down {
          @apply ml-2 hidden h-0 w-0 border-x-4 border-t-4 border-solid border-x-transparent border-t-black lg:block;
        }
        .dropdown-item {
          @apply flex h-[35px] w-full items-center px-2 font-ibm text-[15px]
            font-medium leading-[19.5px] tracking-[-0.3px] first:rounded-t-[5px] last:rounded-b-[5px] hover:bg-white-100;
        }
      `}</style>
      {components.styles}
    </div>
  )
}

const components = css.resolve`
  .btn-access,
  .btn-transparent {
    @apply font-inter text-[15px] leading-[18.15px];
  }
  .btn-access {
    @apply py-[16px] px-[24px];
  }
  .btn-transparent {
    @apply !border-transparent px-4 font-medium tracking-[0.3px] !text-gray-900;
  }
  .btn-search {
    @apply px-0;
  }
  .dropdown-body {
    @apply absolute right-0 top-full mt-2 w-[220px] rounded-[5px] border border-solid border-gray-900 bg-white;
    z-index: 5;
  }
`

export default UserDropdown
